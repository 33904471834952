@mixin app-component-theme($active-color, $inactive-color, $disabled-color, $sidenav-background-color, $toolbar-background-color) {
  .menu-item.mat-mdc-list-item :hover {
    .mat-mdc-list-item-unscoped-content {
      color: $active-color;
    }
  }

  .menu-item.mat-mdc-list-item.active {
    border-left: 4px solid $active-color;
    margin-left: 0;

    .mat-mdc-list-item-unscoped-content {
      color: $active-color;
    }
  }

  .menu-item.mat-mdc-list-item {
    .mat-mdc-list-item-unscoped-content {
      color: $inactive-color;
    }

    mat-icon {
      vertical-align: bottom;
      margin-right: 5px !important;
      margin-left: 6px;
    }
  }

  .main-sidenav {
    background-color: $sidenav-background-color;

    .mat-list-item-content {
      padding: 0 5px !important;
      width: 100%;
    }

    .active .mat-list-item-content {
      padding: 0 5px 0 3px !important;
    }

    .mat-list-item-disabled {
      background-color: $sidenav-background-color;
      color: $disabled-color;
    }
  }

  .toolbar {
    background-color: $toolbar-background-color;
  }
}
