@mixin scroll-bar-theme($scrollbar-track-background, $scrollbar-track-handle-background, $scrollbar-track-handle-hover) {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: $scrollbar-track-background;
  }

  ::-webkit-scrollbar-corner {
    background-color: $scrollbar-track-background;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $scrollbar-track-background;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: $scrollbar-track-handle-background;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: $scrollbar-track-handle-hover;
  }
}
