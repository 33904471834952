@mixin buttons-theme($primary-color) {
  .primary-btn-border {
    border-radius: 14px !important;
    border-color: $primary-color !important;
  }

  .btn-spacer {
    width: 15px;
    display: inline-flex;
  }
}
