@mixin file-upload-theme($primary-color, $light-background-color, $dark-border-color) {

  .file-upload-container {
    border: 2px dashed $primary-color;
    border-radius: 10px;
  }

  .file-upload-container:hover, .file-upload-container.drag-hover {
    background-color: $light-background-color !important;
  }

  .file-upload-selected-file {
    border-color: $dark-border-color !important;
  }
}
