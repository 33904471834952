@use '@angular/material' as mat;
@import 'theme';

@include mat.all-component-themes($theme);
@include set-components-theme($theme);

html, body {
  height: 100%;
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media print {
  body {
    height: auto;
  }
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 8px 0;
}

p {
  margin: 0;
}

.spacer {
  flex: 1;
}

.content {
  display: flex;
  flex-direction: column;
}

.content-holder {
  height: 100%;
  overflow-y: hidden;
}

.flex-responsive-row {
  flex-direction: row;
}

@media (max-width: 768px) {
  .flex-responsive-row {
    flex-direction: column;
  }
}

.flexible-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;

  .column {
    width: 48%;
  }

  @media (max-width: 768px) {
    .column {
      width: 100%;
    }
  }
}

/* Grid container */
.grid-row {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 16px;
  align-items: baseline;

  .grid-col-1 {
    grid-column: span 1;
  }

  .grid-col-2 {
    grid-column: span 2;
  }

  .grid-col-3 {
    grid-column: span 3;
  }

  .grid-col-4 {
    grid-column: span 4;
  }

  .grid-col-5 {
    grid-column: span 5;
  }

  .grid-col-6 {
    grid-column: span 6;
  }

  .grid-col-7 {
    grid-column: span 7;
  }

  .grid-col-8 {
    grid-column: span 8;
  }

  .grid-col-9 {
    grid-column: span 9;
  }

  .grid-col-10 {
    grid-column: span 10;
  }

  .grid-col-11 {
    grid-column: span 11;
  }

  .grid-col-12 {
    grid-column: span 12;
  }

  @media (max-width: 768px) {
    [class*="grid-col-"] {
      grid-column: span 12;
    }
  }
}

a {
  cursor: pointer;
  text-decoration: underline;
}

form {
  width: 100%;

  .mat-radio-label-content {
    width: inherit;
  }
}

form > * {
  margin-bottom: 0.8em;
  width: 100%;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.fill-space {
  flex: 1 1 auto;
}

.center-align {
  text-align: center;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.center {
  margin: 0 auto;
}

.center-vertical {
  margin: auto 0;
}

.center-middle {
  margin: auto;
}

.left {
  float: left;
}

.right {
  float: right;
}

.hidden {
  display: none !important;
}

.invisible {
  display: block;
  visibility: hidden;
  height: 0;
  width: 0;
}

.transparent {
  visibility: hidden;
  opacity: 100%;
}

.mat-mdc-dialog-actions {
  justify-content: flex-end !important;
}

.no-focus:focus {
  outline: none;
}

.multiline-tooltip {
  white-space: pre-line;
}

.word-wrap {
  word-break: break-all;
}

.word-wrap-word {
  word-break: break-word;
}

.interactive-cursor {
  cursor: pointer;
}
